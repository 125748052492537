<template>
  <v-card>
    <v-container>
      <v-row dense>
        <v-col cols="12" class="caption grey--text mt-n3" v-if="player.dtPointLock">
          Points Locked on {{player.dtPointLock | unixToLocalDt}}
        </v-col>
        <v-col cols="12" class="title d-flex align-center">
          {{player.name}}
          <template v-if="tournament.showRatings">
            <rating-badge
              v-for="r in tournament.showRatings"
              :key="r.id"
              :systemId="r.id"
              :player="player"
              @click.native="onBadgeClick(r.id)"
              :disabled="selected && selected.systemId !== r.id"
            ></rating-badge>
          </template>
        </v-col>
      <v-divider></v-divider>
      </v-row>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-row>
            <v-col class="text-center" style="max-width:98px">
              <v-avatar
                size="60px"
              >
                <img :src="player.profilePic" alt="alt">
              </v-avatar>
              <v-btn
                color="color3"
                text x-small
                class="px-0"
                :to="`/player/${player.ppId}`"
              >View Profile</v-btn>
            </v-col>
            <v-col>
              <div v-if="tournament.is('truVolley') && !toggle">
                <TruVolleyCard
                  :player="player"
                  :imgHeight="50"
                  :defaultSurface="division.isGrass ? 'grass' : 'beach'"
                ></TruVolleyCard>
              </div>
              <div v-else>
                <table>
                  <tr v-for="p in player.playerPoints.filter(f => !f.system.startsWith('AVP'))" :key="`${player.id}-${p.systemId}`">
                    <td class="text-right pr-0">{{p.system}}:</td>
                    <td>{{p.total | fixed1OrInt}}</td>
                  </tr>
                </table>
              </div>
              <div class="text-right" v-if="tournament.is('truVolley')">
                <v-btn color="color3" x-small text @click="toggle = !toggle">show {{ toggle ? 'TruVolley' : 'other' }}</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="xsmall text-right">
              {{player.memberIds | pipeArray}}
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :key="1">
          <v-slide-x-transition mode="out-in">
            <rating-history-card
              :key="selected.systemId"
              v-if="selected"
              :player="selected"
              @close="window=0; sId = null"
              :load="true"
              :active="!!selected"
            ></rating-history-card>
          </v-slide-x-transition>
        </v-window-item>
      </v-window>
    </v-container>
  </v-card>
</template>

<script>
const RatingBadge = () => import('@/components/Ratings/RatingBadge.vue')
const RatingHistoryCard = () => import('@/components/Ratings/RatingHistoryCard.vue')
const TruVolleyCard = () => import('./TruVolleyCard.vue')

export default {
  props: ['player', 'tournament', 'division'],
  data () {
    return {
      window: 0,
      sId: null,
      toggle: false
    }
  },
  computed: {
    selected () {
      return this.sId ? this.player.ratings.find(f => f.systemId === this.sId) : this.player.ratings[0]
    }
  },
  methods: {
    onBadgeClick (id) {
      this.sId = id
      this.window = 1
    }
  },
  components: {
    RatingBadge,
    RatingHistoryCard,
    TruVolleyCard
  }
}
</script>
